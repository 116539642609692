import {Link} from 'gatsby';
import React from 'react';
import Image from './Image';

const BrandOtherlink = () => (
    <>
      <div className="brand-otherlink-area">
        <div className="md:flex">
          <div className="md:w-1/2 md:mr-4 my-12">
            <p className="title small text-white">PURCHASE<span>薪ストーブを購入する</span></p>
            <div className="mt-6 c-gray">
              <p>薪ストーブの種類（暖房方式やサイズなど）は様々です。お客様のライフスタイルにピッタリ合った薪ストーブの選び方をご紹介いたします。</p>
              <p className="mt-6">情報の収集からはじまり、プランニング、見積りの入手を経てご購入いただいてから、煙道部材等を手配し、取付施工完了、引き渡しまでに至る大まかな流れと期間についてご説明いたします。</p>
            </div>
          </div>
          <div className="flex md:w-1/2 md:ml-4">
            <Link to="/beginner/" className="item-list w-1/2 mr-4">
              <Image filename="brand/purchase-01.png" />
              <p>薪ストーブの<br className="sp" />選び方</p>
            </Link>
            <Link to="/beginner/#flow" className="item-list w-1/2 ml-4">
              <Image filename="brand/purchase-02.png" />
              <p>価格と設置<br className="sp" />までの流れ</p>
            </Link>
          </div>
        </div>
        <div className="bg md:flex mt-16">
          <div className="md:w-9/12">
            <div className="md:flex md:flex-row-reverse md:justify-end">
              <div className="md:w-4/12 my-12 md:mx-4">
                <p className="title small text-white">ACCESSORY<span>アクセサリを探す</span></p>
                <p className="md:mt-6 c-gray">薪ストーブには煙道部材やアクセサリが必要です。<br />
                  煙突等の種類や設置環境等による配管レイアウトなど、薪ストーブの利用に関する注意事項や機能性の高いアクセサリをご紹介いたします。</p>
              </div>
              <div className="flex md:w-8/12 md:mr-4">
                <Link to="/chimney/" className="item-list w-1/2 mr-4">
                  <Image filename="brand/accessory-01.png" />
                  <p>煙突</p>
                </Link>
                <Link to="/accessory/" className="item-list w-1/2 ml-4">
                  <Image filename="brand/accessory-02.png" />
                  <p>その他の<br className="sp" />アクセサリ</p>
                </Link>
              </div>
            </div>
            <div className="md:flex mt-16">
              <div className="md:w-8/12 md:mr-4 my-12">
                <p className="title small text-white">MAINTENANCE<span>メンテナンスについて</span></p>
                <p className="md:mt-6 c-gray">薪ストーブを安全に使っていただくためには、日々のメンテナンス、オフシーズンには煙突掃除など定期的なケアが重要です。<br />
                  薪ストーブの基本的な使い方、メンテナンス方法やそれらに必要となる必須アイテムなど、薪ストーブの基礎知識をご紹介いたします。</p>
              </div>
              <div className="md:w-4/12 md:mx-4">
                <Link to="/beginner/#maintenance" className="item-list">
                  <Image filename="brand/maintenance-01.png" />
                  <p>メンテナンス</p>
                </Link>
              </div>
            </div>
          </div>
          <div className="bg-image md:w-3/12 md:ml-4" />
        </div>
        <div className="content-block">
          <div className="md:pt-12">
            <div className="md:flex md:pt-12">
              <div className="md:w-1/2">
                <Link to="/movie/" className="other-link left">
                  <div className="hover-ani">
                    <Image className="full-image" filename="brand/movie-01.jpg" />
                    <div className="more">
                      <p className="title small text-white pc">VIEW MORE<span>動画をみる</span></p>
                      <p className="title small text-white sp">MOVIE<span>動画をみる</span></p>
                    </div>
                  </div>
                  <p className="name title large text-white pc">MOVIE</p>
                </Link>
              </div>
              <div className="md:w-1/2 mt-12">
                <Link to="/works/" className="other-link right">
                  <div className="hover-ani">
                    <Image className="full-image" filename="brand/case-01.jpg" />
                    <div className="more">
                      <p className="title small text-white pc">VIEW MORE<span>導入事例をみる</span></p>
                      <p className="title small text-white sp">CASE STUDY<span>導入事例をみる</span></p>
                    </div>
                  </div>
                  <p className="name title large text-white pc">CASE<br />STUDY</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="showroom-link">
          <div className="md:flex">
            <div className="md:w-1/2">
              <Link className="md:hover:opacity-70 transition-opacity" to="/showroom/#sapporo">
                <Image className="w-full" filename="brand/showroom-01.jpg" />
                <div className="text-white text-center">
                  <p className="title en text-2xl">SAPPORO<span>北海道ショールーム</span></p>
                  <p className="c-gray mt-4 text-xs">〒063-0812<br className="sp" /> 札幌市西区琴似2条7丁目2-1<br />
                    営業時間:9:00-17:30<br />
                    定休日：土曜日、日曜日、祝日</p>
                </div>
              </Link>
            </div>
            <div className="md:w-1/2">
              <Link className="md:hover:opacity-70 transition-opacity" to="/showroom/#matudo">
                <Image className="w-full" filename="brand/showroom-02.jpg" />
                <div className="text-white text-center">
                  <p className="title en text-2xl">MATSUDO<span>松戸ショールーム</span></p>
                  <p className="c-gray mt-4 text-xs">〒063-0812<br className="sp" /> 札幌市西区琴似2条7丁目2-1<br />
                    営業時間:9:00-17:30<br />
                    定休日：土曜日、日曜日、祝日</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="content-block">
          <Link to="/agencies/" className="btn black full">その他正規代理店についてはこちら</Link>
        </div>
      </div>
    </>
);

export default BrandOtherlink;


