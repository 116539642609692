import  React from 'react';
import { Link } from 'gatsby';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {Autoplay, EffectFade, Navigation, Thumbs} from 'swiper';
import Layout from '../../../components/layout';
import Seo from '../../../components/seo';
import ListBrand from '../../../components/list-brand';
import ListLink from '../../../components/list-link';
import BrandOtherlink from '../../../components/list-brand-otherlink';
import 'swiper/swiper-bundle.min.css';
import Image from '../../../components/Image';

SwiperCore.use([Navigation, Thumbs, EffectFade, Autoplay]);

const MorsoPage = () => (
      <Layout page="brand-page leda">
        <Seo title="LEDA"/>
        <section className="page-head">
          <ListBrand/>
          <ListLink/>
          <h2 className="flex items-end md:ml-16">
            <Image filename="logo/leda.png" width={180} />
            <p className="text-3xl ml-4">/ レダ</p>
          </h2>
        </section>
        <section className="page-content full-layout">
          <div className="main-content">
            <Swiper
                className="page-main-slider"
                loop
                autoplay={{delay: 4000}}
                speed={1000}
                navigation
                centeredSlides
                breakpoints={{
                  768: {
                    slidesPerView: 1.6,
                  },
                }}
            >
              <SwiperSlide>
                <Image className="full-image" filename="brand/leda/slide-01.jpg" />
              </SwiperSlide>
              <SwiperSlide>
                <Image className="full-image" filename="brand/leda/slide-02.jpg" />
              </SwiperSlide>
            </Swiper>
            <div className="content-block">
              <div className="md:flex justify-center">
                <div className="flex md:w-2/5 md:pr-4">
                  <div className="item-content w-1/2 pr-3">
                    <Link to="/brand/leda/detail/?id=ALLEGRA500">
                      <img src="/update/brand/leda/ALLEGRA500/item01.png" alt="ALLEGRA500" />
                      <p>ALLEGRA500</p>
                    </Link>
                  </div>
                  <div className="item-content w-1/2 pl-3">
                    <Link to="/brand/leda/detail/?id=ALLEGRA350">
                      <img src="/update/brand/leda/ALLEGRA350/item01.png" alt="ALLEGRA350" />
                      <p>ALLEGRA350</p>
                    </Link>
                  </div>
                </div>
                <div className="item-content md:w-1/5 w-1/2 md:pl-5">
                  <Link to="/brand/leda/detail/?id=ARUBA450">
                    <img src="/update/brand/leda/ARUBA450/item01.png" alt="ARUBA450" />
                    <p>ARUBA450</p>
                  </Link>
                </div>
              </div>
              <Link to="/brand/leda/lineup/" className="btn full white mt-12">LEDA 商品一覧</Link>
            </div>
            <div className="about-area">
              <h2 className="sub-title text-black">ABOUT</h2>
              <div className="text-box first">
                <p className="md:text-3xl text-2xl c-yellow mb-6">
                  <span className="leading-relaxed tracking-widest">約150年の鋳物生産の<br />
                    歴史を持つドイツ企業</span>
                </p>
                <p>ドイツ北西部ニーダーザクセン州。その中でも西部のオランダ国境に近い都市レーア（Leer）で1873年にLEDA社は鋳物工場を立ち上げました。それ以来150年近くに亘り工業大国ドイツの鋳物産業を牽引しています。創業当時は薪ストーブではなく産業用の鋳物製品を主としておりそこで培われた高い鋳物生産技術を薪ストーブに活かしています。</p>
              </div>
              <div className="md:flex">
                <Image filename="brand/leda/about-01.jpg" />
                <div className="text-box">
                  <p>社名のLEDAは近くを流れるLEDA川を由来としておりレーア市内の車止めのポールやモニュメントを手掛けたり自社製品のデザインを地元の大学と連携しておこなうなど地域に密着した企業活動を続けており地域に愛される企業として名をはせています。</p>
                  <Link to="/brand/leda/about/" className="text-link white en py-0 text-lg mt-8">VIEW MORE</Link>
                </div>
              </div>
            </div>
            <div className="content-block">
              <div className="bg-gray md:flex px-4 py-8">
                <p className="text-2xl c-yellow md:w-2/12 md:mb-6 mb-2">
                  <span className="leading-relaxed">安心の<br className="pc" />保証期間</span>
                </p>
                <p className="md:w-10/12">アンデルセンストーブはメーカーの厳密な検査のうえ出荷されており日本に到着後も欠けや割れ、塗装の確認、各可動部の異常などの点検をおこなってからお客様の元へお送りさせていただきます。<br />
                  製品の保証期間は5年間となっており納入時にお渡しする保証書兼取扱説明確認書に従った正常な使用状態で期間内に本体の破損・故障が生じた場合は部品の無償提供や修理などをおこないます。ただし炉内の部品など消耗品は対象外となりますので詳しくは納入時にお渡しする保証規定書をご確認ください。</p>
              </div>
            </div>
            <BrandOtherlink />
          </div>
        </section>
        <section className="bread mt-0">
          <ul>
            <li>
              <Link to="/">
                <Image filename="ico/home.png" />
              </Link>
            </li>
            <li>
              <Link to="/brand/">ブランド</Link>
            </li>
            <li>LEDA</li>
          </ul>
        </section>
      </Layout>
  );

export default MorsoPage;
